import React, { Children } from "react"
import { FC } from "react"
import * as CSS from "csstype"
import styled from "styled-components"
import { Box } from "./Box"

const Stacker = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const StackElement = styled.div`
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
`

interface Props {
    space?: CSS.Properties["paddingLeft"]
}

export const Stack: FC<Props> = ({ children, space }): JSX.Element => (
    <Stacker>
        {Children.map(children, (child, index) => (
            <>
                <StackElement>{child}</StackElement>
                {space ?? index < Children.count(children) ? (
                    <StackElement>
                        <Box paddingTop={space} />
                    </StackElement>
                ) : null}
            </>
        ))}
    </Stacker>
)
