import React from "react"
import Link from "gatsby-link"
import Helmet from "react-helmet"
import styled from "styled-components"
import "./DefaultLayout.css"
import { ResponsiveNav } from "./ResponsiveNav"
import { ThemeProvider } from "./ThemeProvider"

const Background = styled.div`
    background: ${({ theme }) => theme.colours.background};
    margin-bottom: 1.45rem;
`

const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.colours.secondary};
    text-decoration: none;
    font-size: 30pt;
    font-weight: bold;
`

const Header = () => {
    return (
        <Background>
            <ResponsiveNav>
                <StyledLink to="/">About</StyledLink>
                <StyledLink to="/games">Games</StyledLink>
                <StyledLink to="/science">Science</StyledLink>
                <StyledLink to="/codebar">codebar</StyledLink>
            </ResponsiveNav>
        </Background>
    )
}

const Body = styled.div`
    margin: 0 auto;
    max-width: 960px;
    padding: 0px 1.0875rem 1.45rem;
    padding-top: 0;

    .emojione {
        margin: 0px;
        display: inline;
        height: 1em;
        width: auto;
        vertical-align: text-top;
    }

    a {
        color: ${({ theme }) => theme.colours.link};
    }

    color: ${({ theme }) => theme.colours.primary};
`

const PageBackground = styled.div`
    background: ${({ theme }) => theme.colours.background};
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
`

const DefaultLayout = (props: React.HTMLProps<HTMLDivElement>): JSX.Element => (
    <ThemeProvider>
        <Helmet
            title="James Elderfield"
            meta={[
                {
                    name: "description",
                    content: "James Elderfield's personal webiste",
                },
                { name: "keywords", content: "dev, personal" },
            ]}
        />
        <PageBackground />
        <Header />
        <Body>{props.children}</Body>
    </ThemeProvider>
)

export default DefaultLayout
