import React, { FC } from "react"
import { ThemeProvider as StyledThemeProvider } from "styled-components"
import "styled-components"

const theme = {
    colours: {
        primary: "rgba(20, 20, 20, 1)",
        secondary: "rgba(200, 70, 48, 1)",
        tertiary: "#727696",
        highlight: "#7a1fcf",
        background: "rgba(215, 215, 220, 1)",
        link: "#8f1e49",
    },
}

export type Theme = typeof theme

export const ThemeProvider: FC<unknown> = ({ children }) => (
    <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
)
