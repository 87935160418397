import React, { FC, ReactNode, useState } from "react"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { Stack } from "./layout/Stack"
import { Hamburger } from "./Hamburger"
import { useEffect } from "react"

const Nav = styled.nav`
    display: flex;
    margin: 0 auto;
    padding: 1.45rem;
    max-width: 960px;
    overflow-y: visible;
`

const Content = styled.div<{ isVertical?: boolean }>`
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    flex-direction: ${({ isVertical }) => (isVertical ? "column" : "row")};
`

const FlexItem = styled.div`
    display: flex;
    padding: 0 10px;
    flex-grow: 1;
`

const ContentItem = styled(FlexItem)`
    padding-bottom: 5px;
`
const CollapseWrapper = styled.div<{ collapsed?: boolean }>`
    overflow: hidden;
    position: absolute;
    top: 50px;
    padding: 15px;
    border: 2px solid ${({ theme }) => theme.colours.secondary};
    opacity: ${({ collapsed }) => (collapsed ? "0" : "1")};
    background: ${({ theme }) => theme.colours.background};
    transition: max-height 0.5s ease-in;
    transition: opacity 0.1s ease-in;
    max-height: ${({ collapsed }) => (collapsed ? "0" : "500px")};
`

const SmallNav: FC<unknown> = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleMenu = () => setIsExpanded(!isExpanded)

    return (
        <Content isVertical>
            <FlexItem>
                <Hamburger onClick={toggleMenu} />
            </FlexItem>
            <CollapseWrapper collapsed={!isExpanded}>
                {React.Children.map(children, (child: ReactNode) => (
                    <ContentItem>{child}</ContentItem>
                ))}
            </CollapseWrapper>
        </Content>
    )
}

const NavDecoration = styled.div`
    width: 75%;
    height: 5px;
    background: ${({ theme }) => theme.colours.secondary};
`

const LargeNav: FC<unknown> = ({ children }) => (
    <Stack space="10px">
        <Content>
            {React.Children.map(children, (child: ReactNode) => (
                <ContentItem>{child}</ContentItem>
            ))}
        </Content>
        <NavDecoration />
    </Stack>
)

const LoadingSkeleton = styled.div`
    height: 52px;
`

export const ResponsiveNav: FC<unknown> = ({ children }) => {
    const [isMounted, setIsMounted] = useState(false)

    const isSmallScreen = useMediaQuery({ maxWidth: 720 })

    useEffect(() => {
        setIsMounted(true)
    })

    return (
        <Nav>
            {!isMounted ? (
                <LoadingSkeleton />
            ) : isSmallScreen ? (
                <SmallNav>{children}</SmallNav>
            ) : (
                <LargeNav>{children}</LargeNav>
            )}
        </Nav>
    )
}
