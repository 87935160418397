import styled from "styled-components"
import * as CSS from "csstype"

type Props = Pick<
    CSS.Properties,
    "paddingLeft" | "paddingRight" | "paddingBottom" | "paddingTop"
>

export const Box = styled.div<Props>`
    ${({ paddingLeft }) => `padding-left: ${paddingLeft};`}
    ${({ paddingRight }) => `padding-right: ${paddingRight};`}
    ${({ paddingTop }) => `padding-top: ${paddingTop};`}
    ${({ paddingBottom }) => `padding-bottom: ${paddingBottom};`}
`
