import React, { FC } from "react"
import { Menu } from "react-feather"
import { useTheme } from "styled-components"

export const Hamburger: FC<{ onClick: () => void }> = ({ onClick }) => {
    const theme = useTheme()

    return (
        <div onClick={onClick} onKeyPress={onClick} role="button" tabIndex={0}>
            <Menu
                fill={theme.colours.secondary}
                stroke={theme.colours.secondary}
                size={48}
            />
        </div>
    )
}
